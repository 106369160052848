import _, { add } from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useGameTimer } from '../util'
import { roll } from '../logic'

function Dice({ sides = 4 }) {
  const [value, setValue] = useState(sides)
  return (
    <button onClick={useCallback(() => setValue(roll(1, sides)), [])}>
      d{sides}: {value}
    </button>
  )
}
const itemTable = {
  potion: [1, 20]
}
const weaponTable = {
  fist: [1, 4, 0],
  rock: [1, 6, 0],
  club: [1, 6, 1],
  sword: [1, 8, 2],
  flamethrower: [10, 4, 0]
}

const enemyTable = {
  dragon: { health: 1000, exp: 100000, loot: { gold: [1e3, 1e4] }, weapon: 'flamethrower' },
  train: { health: 100, exp: 10000, loot: { gold: [500, 1000] } },
  bandit: { health: 20, exp: 1000, loot: { gold: [100, 500], weapon: 'club' }, weapon: 'club', potion: 'health' },
  snake: { health: 10, exp: 100, loot: { gold: [1, 25], potion: 'poison' } },
  rabbit: { health: 5, exp: 10, loot: { gold: [10, 100], weapon: 'rock', potion: 'health' } },
  ant: { health: 1, exp: 1, loot: { gold: [1, 3] } }
}

export function Rpg() {
  const [speed, setSpeed] = useState(1)
  const pause = useCallback(() => setSpeed(0), [])
  const play = useCallback(() => setSpeed(1), [])
  const gameTimer = useGameTimer(speed)
  const [health, setHealth] = useState(15)
  const [weapon, setWeapon] = useState('fist')
  const [isDragon, setIsDragon] = useState(false)

  const [gold, setGold] = useState(0)
  const [exp, setExp] = useState(0)
  const [kills, setKills] = useState(0)

  const [enemy, setEnemy] = useState('ant')
  const [enemyHealth, setEnemyHealth] = useState(5)
  const [enemyWeapon, setEnemyWeapon] = useState('fist')
  const [screen, showScreen] = useState('')

  const [log, setLog] = useState([])
  const addToLog = useCallback(
    (message) => {
      log.push(message)
      setLog(_.slice(log, -10))
    },
    [log]
  )

  const [inventory, setInventory] = useState([])

  const addToInventory = useCallback(
    (item) => {
      inventory.push(item)
      setInventory(inventory.slice())
    },
    [inventory]
  )

  const useItem = useCallback(
    (item) => {
      switch (item.type) {
        case 'weapon':
          setWeapon(item.name)
          addToLog(`You equip a ${item.name}.`)
          break
        case 'potion':
          switch (item.name) {
            case 'health':
              const healAmount = roll()
              setHealth(health + healAmount)
              addToLog(`You drank a potion, and gained ${healAmount} health.`)
              break
            case 'poison':
              const damage = roll()
              setEnemyHealth(enemyHealth - damage)
              addToLog(`You threw a potion, and did ${damage} damage.`)

              if (screen === 'resting') {
                enemyTable.vendor = {
                  health: 150,
                  exp: 5000,
                  weapon: 'sword',
                  loot: { gold: [1000, 5000], weapon: 'sword', potion: 'health' }
                }
                startFight('vendor')
                setEnemyHealth(150 - damage)
                addToLog('You have hit the vendor!')
              }
              break
          }
          break
        case 'heart':
          if (screen === 'dragon') {
            setHealth(health - 1000)
            addToLog(`You eat the heart, it saps your strength and you ${health > 1000 ? 'lived' : 'died'}!`)
            showScreen(health > 1000 ? 'win' : 'gameover')
            setIsDragon(health > 1000)
          } else {
            showScreen('dragon')
          }
          return
      }

      setInventory(_.without(inventory, item))
    },
    [inventory, health, screen]
  )

  const startFight = useCallback((newEnemy) => {
    setEnemy(newEnemy)
    setEnemyHealth(enemyTable[newEnemy].health)
    setEnemyWeapon(enemyTable[newEnemy].weapon ?? 'fist')
    showScreen('fighting')
    play()
  }, [])

  const buyPotion = useCallback(() => {
    addToInventory({ type: 'potion', name: 'health' })
    setGold(gold - 10)
  }, [gold, addToInventory])

  const onRestart = useCallback(() => {
    setHealth(isDragon ? 1000 : 15)
    setWeapon(isDragon ? 'flamethrower' : 'fist')
    setEnemy('ant')
    setEnemyHealth(10)
    setEnemyWeapon('fist')
    setExp(0)
    setKills(0)
    setLog([])
    setInventory([])
    setGold(0)
    showScreen('fighting')
    play()
  }, [isDragon])
  useEffect(() => {
    if (speed !== 0) {
      let message = ''
      let nextEnemyHealth = enemyHealth
      if (enemyHealth <= 0) {
        // already dead
        message += `You have killed ${enemy}.`
      } else {
        // attack phase
        const damageOut = roll(...weaponTable[weapon])
        nextEnemyHealth = enemyHealth - damageOut
        setEnemyHealth(nextEnemyHealth)
        if (nextEnemyHealth <= 0) {
          message = `You hit ${enemy} for ${damageOut} damage and killed it.`
        }

        if (nextEnemyHealth > 0) {
          // enemy turn
          const damageIn = roll(...weaponTable[enemyWeapon])
          const nextHealth = health - damageIn
          addToLog(`You hit ${enemy} for ${damageOut} damage, and it hit you for ${damageIn}.`)
          setHealth(nextHealth)

          if (nextHealth <= 0) {
            // death
            pause()
            addToLog('You died!')
            showScreen('gameover')
          }
        }
      }

      if (nextEnemyHealth <= 0) {
        // enemy killed
        setKills(kills + 1)
        const { loot, exp: newExp } = enemyTable[enemy]
        const foundGold = _.random(...loot.gold)
        setExp(exp + newExp)
        setGold(gold + foundGold)
        addToLog(
          message +
            ` Found ${foundGold} gold${loot.weapon ? ` and a ${loot.weapon}` : ''}${
              loot.potion ? ` and a ${loot.potion} potion` : ''
            }.`
        )
        if (loot.weapon) {
          addToInventory({ type: 'weapon', name: loot.weapon })
        }
        if (loot.potion) {
          addToInventory({ type: 'potion', name: loot.potion })
        }

        pause()
        if (enemy === 'dragon' && inventory.every((i) => i.type !== 'heart')) {
          addToInventory({ type: 'heart', name: enemy })
          addToLog(`You tear the heart out of the dragon.`)
        }
        showScreen('resting')
      }
    }
  }, [gameTimer])
  return (
    <div>
      <Dice sides={4} />
      <Dice sides={6} />
      <Dice sides={10} />
      <Dice sides={20} />

      {screen === 'resting' && (
        <>
          <div>
            Pick a fight:
            {
              Object.keys(enemyTable).map((i) => (
                <button onClick={() => startFight(i)}>{i}</button>
              ))
              //   .slice((index = _.random(0, Object.keys(enemyTable).length - 4)), index + 4)
            }
          </div>
          <div>
            Buy:
            <button disabled={gold < 10} onClick={buyPotion}>
              Potion (10 gold)
            </button>
          </div>
        </>
      )}
      {screen === 'dragon' && (
        <div>
          <p>Only those with a dragon's strength can withstand the heart.</p>
          <p>Are you ready?</p>
          <button onClick={() => useItem({ type: 'heart', name: 'dragon' })}>Yes</button>
          <button onClick={() => showScreen('resting')}>No</button>
        </div>
      )}
      {screen === 'win' && (
        <div>
          You have become as healthy as a dragon!
          <div>Kills: {kills}</div>
          <div>Exp: {exp}</div>
          <button onClick={onRestart}>Restart?</button>
        </div>
      )}
      {screen === 'gameover' && (
        <div>
          You have died! Game over!
          <div>Kills: {kills}</div>
          <div>Exp: {exp}</div>
          <button onClick={onRestart}>Restart?</button>
        </div>
      )}
      {screen === 'fighting' && (
        <div>
          {enemy}: {enemyHealth}hp, using {enemyWeapon}
        </div>
      )}
      <div>
        you: {health}hp, using {weapon}. Holding:
        <ul>
          <li>{gold} gold.</li>
          {inventory.map((i) => (
            <li>
              {i.name} {i.type}
              <button disabled={i.type === 'heart' && screen !== 'resting'} onClick={() => useItem(i)}>
                {i.type === 'weapon' ? 'Equip' : i.type === 'heart' ? 'Eat' : 'Use'}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <textarea cols={80} rows={10} value={log.join('\r\n')}></textarea>
    </div>
  )
}
