import _ from 'lodash'
import { useCallback, useEffect, useState, useContext } from 'react'
import { ResourceContext, initialResources } from './model'
import { reduce, getSupply } from './logic'

/**
 * @param {Resources} resources
 * @param {Resource['buy']} buy
 * @returns {{[id: string] : number}}
 * @example 1 => {gold : 1}
 * @example [10, '**', 3] => {gold : 1000}
 * @example {wood: [10, '*', 3]} => {wood: 30}
 */
function reduce2(resources, buy) {
  if (typeof buy === 'object' && !_.isArray(buy)) {
    return _.mapValues(buy, (cost) => Math.ceil(getSupply(resources, cost)))
  }
  const gold = Math.ceil(getSupply(resources, buy))
  return gold ? { gold } : {}
}

export function useResourceProvider(gameTimer) {
  const [resources, setResources] = useState(initialResources)
  const set = useCallback((id, supply) => setResources(_.set({ ...resources }, `${id}.supply`, supply)), [resources])
  const add = useCallback((id, amount) => set(id, (resources[id]?.supply ?? 0) + amount), [resources])
  const subtract = useCallback((id, amount) => add(id, -amount), [resources])
  const buy = useCallback(
    (id, costs, amount = 1) => {
      add(id, amount)
      _.forEach(costs, (cost, from) => subtract(from, cost * amount))
    },
    [resources]
  )
  const sell = useCallback(
    (id, sales, amount = 1) => {
      subtract(id, amount)
      _.forEach(sales, (sale, from) => add(from, sale * amount))
    },
    [resources]
  )
  // const gameTimer = useGameTimer(5)
  useEffect(() => {
    let resourcesWithTime = { ...resources, time: gameTimer }
    const nextResources = _.mapValues(resources, ({ supply }) => supply ?? 0)

    _.map(resources, ({ buyer, ...r }) => ({
      ...r,
      buyer: typeof buyer === 'boolean' ? (buyer ? 1 : 0) : getSupply(resourcesWithTime, buyer)
    }))
      .filter(({ buyer }) => buyer > 0)
      .map((r) => ({ ...r, ...getBuyData(resourcesWithTime, r.buy) }))
      .forEach(({ buyer, id, costs }) => {
        const buyAmount = Math.floor(
          _.reduce(costs, (result, cost, key) => Math.min(nextResources[key] / cost, result), buyer)
        )
        if (buyAmount) {
          nextResources[id] += buyAmount
          _.forEach(costs, (cost, key) => (nextResources[key] -= cost * buyAmount))
          buy(id, costs, buyAmount)
        }
      })

    _.map(resources, ({ seller, ...r }) => ({
      ...r,
      seller: typeof seller === 'boolean' ? (seller ? 1 : 0) : getSupply(resourcesWithTime, seller)
    }))
      .filter(({ seller }) => seller > 0)
      .map((r) => ({ ...r, sales: reduce(resources, r.sell) }))
      .forEach(({ supply, seller, id, sales }) => {
        console.debug('sell', id, supply, seller, sales)
        const sellAmount = Math.min(supply, seller)
        if (sellAmount) {
          nextResources[id] -= sellAmount
          _.forEach(sales, (sale, key) => (nextResources[key] += sale * sellAmount))
          sell(id, sales, sellAmount)
        }
      })
  }, [gameTimer])

  return { resources: { ...resources, time: gameTimer }, set, add, subtract, buy, sell }
}

/**
 * @param {Resources} resources
 * @param {ResourceDescriptor} buy
 */
function getBuyData(resources, buy) {
  const costs = reduce(resources, buy)
  const canBuy = buy === undefined ? undefined : _.every(costs, (cost, from) => cost <= getSupply(resources, from))
  return { costs, canBuy }
}

export function useResource2(id) {
  const { resources, add, subtract } = useContext(ResourceContext)
  let {
    supply = 0,
    buy,
    sell
  } = typeof resources[id] === 'number' ? { supply: resources[id] } : resources[id] ?? { supply: 0 }
  const { costs, canBuy } = getBuyData(resources, buy)
  const sales = reduce(resources, sell)
  const canSell = sell === undefined ? undefined : supply > 0

  const onBuy = useCallback(() => {
    add(id, 1)
    _.forEach(costs, (cost, from) => subtract(from, cost))
  }, [supply])

  const onSell = useCallback(() => {
    subtract(id, 1)
    _.forEach(sales, (sale, from) => add(from, sale))
  }, [supply])

  return { supply, costs, canBuy, onBuy, sales, canSell, onSell }
}

export function useGameTimer(speed = 1) {
  const [gameTimer, setGameTimer] = useState(0)
  useEffect(() => {
    let counter = gameTimer
    if (speed !== 0) {
      const id = window.setInterval(() => {
        setGameTimer((counter += 1))
      }, 1000 / speed)
      return () => {
        window.clearInterval(id)
      }
    }
  }, [speed])

  return gameTimer
}

/**
 * @param {number} gold
 * @param {number} setGold
 * @param {number} initialAmount
 * @param {number} initialCost
 * @param {number} growthFactor (default = 1)
 * @param {} options
 * @returns {[number, number, Function, Function]} [amount, cost, onBuy, setAmount]
 */
export function useResource(gold, setGold, initialAmount, initialCost, growthFactor = 1, options = {}) {
  const { decreasing = false, step = 1 } = options

  const [cost, setCost] = useState(Math.ceil(initialCost))
  const [amount, setAmount] = useState(initialAmount)
  const onBuy = useCallback(() => {
    setGold(gold - cost)
    const newAmount = decreasing ? amount - step : amount + step
    setAmount(newAmount)
    setCost(Math.ceil(initialCost * growthFactor ** Math.abs((initialAmount - newAmount) / step)))
  }, [gold, cost, amount])

  return [amount, cost, onBuy, setAmount]
}

/**
 *
 * @param {number | {[id: string]: number}} value
 * @returns
 */
export function format(value) {
  if (typeof value === 'number') {
    return new Intl.NumberFormat().format(value)
  }

  return Object.keys(value)
    .map((k) => `${format(value[k])} ${k}`)
    .join(' & ')
}
