import { createContext } from 'react'
import _ from 'lodash'
/**
 * @typedef {'+' | '-' | '*' | '/' | '**' | '%' | '<' | '<=' | '>' | '>=' | '==' | '!='} Operator
 * @typedef {[Operator, ...(ValueOrId)[]]} Operation
 * @typedef {number | string | Operation} ValueOrId
 * @typedef {ValueOrId | {[id: string]: ValueOrId}} ResourceDescriptor
 * @typedef {{[id: string]: number}} ResourceValues
 * @typedef {{
 *  id: string
 *  supply: number
 *  buy?: ResourceDescriptor
 *  sell?: ResourceDescriptor
 *  buyer?: boolean | ValueOrId
 *  seller?: boolean | ValueOrId
 * }} Resource
 * @typedef {{[id: string]: Resource}} Resources
 */

/**
 * @type {Resources}
 */
export const initialResources = _.keyBy(
  [
    { id: 'gold', supply: 1e4 },
    { id: 'gameSpeed', supply: 1, buy: 1e6 },
    { id: 'wood' },
    { id: 'farmer', buy: '(* 15 (** 1.05 farmer))' },
    { id: 'fieldGrowth', supply: 10, buy: 100 },
    { id: 'fieldProfit', supply: 10, buy: 10 },
    { id: 'fieldSize', supply: 10, buy: 1 },
    { id: 'ore', buyer: 'miner' },
    { id: 'miner', buy: '(* 100 (** 1.05 miner))', sell: {} },
    { id: 'metal', buy: { ore: 10, gold: 10 }, buyer: 'smelter' },
    { id: 'smelter', buy: '(* 100 (** 1.05 smelter))', sell: {} },
    { id: 'sword', buy: { metal: 10, gold: 100 }, sell: 1e4, buyer: 'weaponsmith', seller: 'weaponseller' },
    { id: 'weaponsmith', buy: '(* 100 (** 1.05 weaponsmith))', sell: {} },
    { id: 'weaponseller', buy: '(* 100 (** 1.05 weaponseller))', sell: {} },
    { id: 'gauntlet', buy: { metal: 2, gold: 1e4 }, buyer: 'gauntletsmith' },
    { id: 'gauntletsmith', buy: { armorsmith: 1 }, sell: { armorsmith: 1 } },
    { id: 'helmet', buy: { metal: 4, gold: 1e4 }, buyer: 'helmetsmith' },
    { id: 'helmetsmith', buy: { armorsmith: 1 }, sell: { armorsmith: 1 } },
    { id: 'boots', buy: { metal: 3, gold: 1e4 }, buyer: 'bootsmith' },
    { id: 'bootsmith', buy: { armorsmith: 1 }, sell: { armorsmith: 1 } },
    {
      id: 'armor',
      buy: { metal: 25, gauntlet: 2, boots: 2, helmet: 1, gold: 1e5 },
      sell: 1e6,
      buyer: 'armorsmith',
      seller: 'armorseller'
    },
    { id: 'armorsmith', buy: '(* 100 (** 1.05 (+ gauntletsmith helmetsmith bootsmith armorsmith)))', sell: {} },
    { id: 'armorseller', buy: '(* 100 (** 1.05 armorseller))', sell: {} }
  ],
  'id'
)

export const ResourceContext = createContext({
  resources: initialResources,
  set(id, supply) {},
  add(id, amount) {},
  subtract(id, amount) {},
  buy(id, amount) {},
  sell(id, amount) {}
})
