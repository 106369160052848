import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

const DELAY = 2 ** 9
/**
 * @TODO make auto-click work with disabled buttons
 * @param {{
 *  onClick: Function,
 *  disabled: boolean
 *  props: React.ButtonHTMLAttributes<HTMLButtonElement>
 * }} props
 */
export function AutoClickButton({ onClick, disabled, ...props }) {
  const [isAutoClicking, setIsAutoClicking] = useState(false)
  const [delay, setDelay] = useState(DELAY)
  const startAutoClicking = useCallback(() => {
    setIsAutoClicking(true)
    setDelay(DELAY)
  }, [])
  const stopAutoClicking = useCallback(() => {
    setIsAutoClicking(false)
  })
  useEffect(() => {
    if (isAutoClicking && !disabled) {
      const id = window.setTimeout(() => {
        onClick()
        setDelay(delay === 32 ? 31 : delay === 31 ? 32 : delay / 2)
      }, delay)
      return () => window.clearTimeout(id)
    }
  }, [delay, isAutoClicking])
  return (
    <button
      {...props}
      onMouseDown={startAutoClicking}
      onMouseLeave={stopAutoClicking}
      onMouseUp={stopAutoClicking}
      onClick={useCallback(() => {
        stopAutoClicking()
        onClick()
      }, [onClick])}
      disabled={disabled}
    ></button>
  )
}
