import _ from 'lodash'
import { Resources, Operation, ValueOrId, ResourceDescriptor, ResourceValues } from './model'

export function debug(message, value) {
  console.debug(message, value)
  return value
}

/**
 *
 * @param {string} expression
 * @returns {Operation}
 */
export function evaluate(expression) {
  const queue = expression.replace(/[()]/g, ' $& ').split(/ |(?<=\()\B|(?=\))\B/g)
  const stack = []
  for (const op of queue) {
    if (op === '') {
      continue
    } else if (op === '(') {
      stack.push([])
    } else if (op === ')') {
      const operation = stack.pop()
      if (!operation) {
        throw new Error(`Expected open parentheses in "${expression}"`)
      }

      if (stack.length === 0) {
        return operation
      } else {
        stack[stack.length - 1].push(operation)
      }
    } else if (stack.length === 0) {
      throw new Error(`Expected open parentheses at ${op} in "${expression}"`)
    } else {
      stack[stack.length - 1].push(_.isNaN(Number(op)) ? op : Number(op))
    }
  }

  throw new Error(`Unmatched open parentheses in "${expression}"`)
}

export function roll(count = 1, sides = 20, modifier = 0) {
  return _.sum(_.range(0, count).map((i) => _.random(1, sides))) + modifier
}
/**
 * @param {Resources} resources
 * @param {Operation} param1
 * @returns {number}
 */
export function operate(resources, [operator, ...operands]) {
  switch (operator) {
    case '+':
      return _.reduce(operands, (x, y) => x + getSupply(resources, y), 0)
    case '*':
      return _.reduce(operands, (x, y) => x * getSupply(resources, y), 1)
  }

  const a = getSupply(resources, operands[0])

  switch (operator) {
    case '-':
      return operands.length === 1 ? -a : _.reduce(operands.slice(1), (x, y) => x - getSupply(resources, y), a)
    case '/':
      return _.reduce(operands.slice(1), (x, y) => x / getSupply(resources, y), getSupply(resources, a))
  }

  const b = getSupply(resources, operands[1])

  switch (operator) {
    case 'roll':
      return roll(a, b)
    case 'random':
      return a && b ? _.random(a, b) : _.random(true)
    case '**':
      return a ** b
    case '%':
      return a % b
  }

  const c = getSupply(resources, operands[2])
  const d = getSupply(resources, operands[3])

  switch (operator) {
    case '!=':
      return a !== b ? c : d
    case '<':
      return a < b ? c : d
    case '<=':
      return a <= b ? c : d
    case '==':
      return a === b ? c : d
    case '>=':
      return a >= b ? c : d
    case '>':
      return a > b ? c : d
  }

  return a
}

/**
 * @param {Resources} resources
 * @param {ValueOrId} valueOrId
 * @returns {number}
 */
export function getSupply(resources, valueOrId) {
  return valueOrId === undefined
    ? 0
    : typeof valueOrId === 'number'
    ? valueOrId
    : _.isArray(valueOrId)
    ? operate(resources, valueOrId)
    : valueOrId.startsWith('(')
    ? operate(resources, evaluate(valueOrId))
    : typeof resources[valueOrId] === 'number'
    ? resources[valueOrId]
    : !!resources[valueOrId]
    ? resources[valueOrId].supply ?? 0
    : debug(`no resource defined: ${valueOrId}`, 0)
}

/**
 * @param {Resources} resources
 * @param {ResourceDescriptor} buy
 * @returns {ResourceValues}
 * @example 1 => {gold : 1}
 * @example [10, '**', 3] => {gold : 1000}
 * @example {wood: [10, '*', 3]} => {wood: 30}
 */
export function reduce(resources, buy) {
  if (typeof buy === 'number') {
    return buy === 0 ? {} : { gold: buy }
  }
  if (typeof buy === 'string' || _.isArray(buy)) {
    return { gold: Math.ceil(getSupply(resources, buy)) }
  }
  if (typeof buy === 'object') {
    return _.mapValues(buy, (cost) => Math.ceil(getSupply(resources, cost)))
  }
  return {}
}
