import { useCallback, useEffect, useState } from 'react'
import { AutoClickButton } from '../AutoClickButton'
import { format, useResource } from '../util'

function AutomateButton({ gold, cost, isAutomated, setAutomate }) {
  const onBuy = useCallback(() => setAutomate(true), [])
  return isAutomated ? null : (
    <button onClick={onBuy} disabled={gold < cost}>
      Automate? ({format(cost)}g)
    </button>
  )
}

export function Factory({ gameTimer, gold, setGold }) {
  const [ore, setOre] = useState(0)
  const [autoOre, setAutoOre] = useState(false)
  const [metal, metalCost, buyMetal, setMetal] = useResource(ore, setOre, 0, 10)
  const [autoMetal, setAutoMetal] = useState(false)
  const [sword, swordCost, buySword, setSword] = useResource(metal, setMetal, 0, 10)
  const [autoSword, setAutoSword] = useState(false)
  const [swordValue, swordValueCost, buySwordValue] = useResource(gold, setGold, 1e9, 1e10, 1.1, {
    step: 5e8
  })
  const sellSword = useCallback(() => {
    setSword(sword - 1)
    setGold(gold + swordValue)
  }, [sword, gold])
  const [autoSell, setAutoSell] = useState(false)
  useEffect(() => {
    if (autoOre) {
      setOre(ore + 1)
    }
    if (autoMetal && ore >= metalCost) {
      buyMetal()
    }
    if (autoSword && metal >= swordCost) {
      buySword()
    }
    if (autoSell && sword) {
      sellSword()
    }
  }, [gameTimer])
  return (
    <div>
      <h2>Factory</h2>
      <div>
        Ore: {ore} pieces
        <AutoClickButton onClick={useCallback(() => setOre(ore + 1), [ore])}>Mine Ore</AutoClickButton>
        <AutomateButton cost={1e8} gold={gold} isAutomated={autoOre} setAutomate={setAutoOre} />
      </div>
      <div>
        Metal: {metal} pieces
        <AutoClickButton disabled={ore < metalCost} onClick={buyMetal}>
          Smelt Metal ({metalCost} ores)
        </AutoClickButton>
        <AutomateButton cost={1e9} gold={gold} isAutomated={autoMetal} setAutomate={setAutoMetal} />
      </div>
      <div>
        Sword: {sword} pieces
        <AutoClickButton disabled={metal < swordCost} onClick={buySword}>
          Craft Sword ({swordCost} metal)
        </AutoClickButton>
        <AutomateButton cost={1e10} gold={gold} isAutomated={autoSword} setAutomate={setAutoSword} />
      </div>
      <div>
        <AutoClickButton onClick={sellSword} disabled={!sword}>
          Sell Sword ({format(swordValue)}g)
        </AutoClickButton>
        <AutomateButton cost={1e11} gold={gold} isAutomated={autoSell} setAutomate={setAutoSell} />
      </div>
    </div>
  )
}
