import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { AutoClickButton } from '../AutoClickButton'
import { format, useResource } from '../util'

export function Field({ field, width = 1, height = 1, onPick, gameTimer }) {
  return (
    <table>
      <tbody>
        {_.range(height).map((i) => (
          <tr key={`${i}`}>
            {_.range(width).map((j) => {
              const isReadyToPick = (field[i]?.[j] ?? 0) <= gameTimer
              return (
                <td
                  key={`${i} ${j}`}
                  style={{ width: 25, height: 25, background: isReadyToPick ? 'green' : 'red' }}
                  onMouseEnter={isReadyToPick ? () => onPick(i, j) : undefined}
                  disabled={!isReadyToPick}
                ></td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export function Plot({}) {
  const [picked, setPicked] = useState([[]])
  return (
    <table>
      {_.range(height).map((i) => (
        <tr key={`${i}`}>
          {_.range(width).map((j) => (
            <td
              key={`${i} ${j}`}
              style={{ width: 25, height: 25, background: 'green' }}
              onClick={() => {
                onPick(i, j)
                setPicked(picked)
              }}
            ></td>
          ))}
        </tr>
      ))}
    </table>
  )
}

function set(field, i, j, value) {
  if (!field[i]) {
    field[i] = []
  }
  field[i][j] = value
}

export function Farm({ gameTimer, gold, setGold }) {
  /** @type{[number[][]]} */
  const [field, setField] = useState([[]])
  const [fieldSize, fieldCost, expandField] = useResource(gold, setGold, 2, 1, 1.5)
  const [growTime, growCost, buyGrowTime] = useResource(gold, setGold, 10, 100, 10, { decreasing: true })
  const [profit, profitCost, buyProfit] = useResource(gold, setGold, 10, 10, 1.1, { step: 10 })

  const [workers, workerCost, buyWorker] = useResource(gold, setGold, 0, 15, 1.05)

  const [performanceMode, setPerformanceMode] = useState(false)

  const pick = useCallback(
    (i, j) => {
      setGold(gold + profit)
      set(field, i, j, gameTimer + growTime)
      setField([...field])
    },
    [gold, field, gameTimer]
  )

  useEffect(() => {
    let plotsPicked = 0
    let workersLeftToPick = workers
    if (performanceMode) {
      const plots = fieldSize * fieldSize
      plotsPicked =
        growTime * workers > plots ? Math.floor(plots / growTime / workers) + (gameTimer % growTime < plots) : workers
      setGold(gold + plotsPicked * profit)
    } else if (workers) {
      donePicking: for (let i = 0; i < fieldSize; i++) {
        for (let j = 0; j < fieldSize; j++) {
          const plot = field[i]?.[j] ?? 0
          if (plot < gameTimer) {
            set(field, i, j, gameTimer + growTime)
            plotsPicked++
            if (!--workersLeftToPick) {
              break donePicking
            }
          }
        }
      }

      setGold(gold + plotsPicked * profit)
      setField([...field])
    }
  }, [gameTimer])

  return (
    <div>
      <h2>Farm</h2>
      <div>
        Workers: {workers}
        <AutoClickButton onClick={buyWorker} disabled={gold < workerCost}>
          Buy Worker ({format(workerCost)}g)
        </AutoClickButton>
      </div>

      <div>
        Growth Rate: {growTime} ticks
        <AutoClickButton onClick={buyGrowTime} disabled={growTime === 1 || gold < growCost}>
          {growTime === 1 ? 'MAX' : `Grow Faster (${format(growCost)}g)`}
        </AutoClickButton>
      </div>
      <div>
        Profits: {format(profit)}g per plot
        <AutoClickButton onClick={buyProfit} disabled={gold < profitCost}>
          Increase Profits ({format(profitCost)}g)
        </AutoClickButton>
      </div>
      <div>
        Size: {fieldSize} x {fieldSize} plots = {fieldSize * fieldSize} plots
        <AutoClickButton onClick={expandField} disabled={gold < fieldCost}>
          Expand ({format(fieldCost)}g)
        </AutoClickButton>
      </div>

      <button onClick={useCallback(() => setPerformanceMode(!performanceMode), [performanceMode])}>
        Performance Mode? {performanceMode && '(Enabled)'}
      </button>
      {!performanceMode && (
        <Field field={field} width={fieldSize} height={fieldSize} onPick={pick} gameTimer={gameTimer} />
      )}
    </div>
  )
}
