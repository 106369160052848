import { useCallback } from 'react'
import { format, useGameTimer, useResource2, useResourceProvider } from '../util'
import { AutoClickButton } from '../AutoClickButton'
import { ResourceContext } from '../model'

function AutomateButton({ gold, cost, isAutomated, setAutomate }) {
  const onBuy = useCallback(() => setAutomate(true), [])
  return isAutomated ? null : (
    <button onClick={onBuy} disabled={gold < cost}>
      Automate? ({format(cost)}g)
    </button>
  )
}
export function GameManager({ children }) {
  const gameTimer = useGameTimer(10)
  // const [gameTimer, setGameTimer] = useState(0)
  return (
    <ResourceContext.Provider value={useResourceProvider(gameTimer)}>
      {/* <button onClick={useCallback(() => setGameTimer(manualTimer + 1), [manualTimer])}>tick</button> */}
      {children}
    </ResourceContext.Provider>
  )
}

export function Manager({ id, buyLabel = 'Buy', sellLabel = 'Sell', label = id }) {
  const { supply, costs, canBuy, onBuy, sales, canSell, onSell } = useResource2(id)
  const cost = format(costs)
  const sale = format(sales)
  return (
    <div>
      {label}: {format(supply)}
      {canBuy !== undefined && (
        <AutoClickButton onClick={onBuy} disabled={!canBuy}>
          {buyLabel} {cost && `(${cost})`}
        </AutoClickButton>
      )}
      {canSell !== undefined && (
        <AutoClickButton onClick={onSell} disabled={!canSell}>
          {sellLabel} {sale && `(${sale})`}
        </AutoClickButton>
      )}
    </div>
  )
}
