import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useState, useCallback } from 'react'
import { useGameTimer, useResource } from '../util'
import { Factory } from '../Factory'
import { Farm } from '../Farm'
import { GameManager, Manager } from '../Manager'
import { Rpg } from '../Rpg'

export function App() {
  const [screen, showScreen] = useState('rpg')
  const showHome = useCallback(() => showScreen(''), [])
  const showRpg = useCallback(() => showScreen('rpg'), [])
  const showManager = useCallback(() => showScreen('manager'), [])
  const showFarmer = useCallback(() => showScreen('farmer'), [])

  return (
    <>
      <h1 onClick={showHome} style={{ cursor: 'pointer' }}>
        Idle Game
      </h1>
      {screen === 'rpg' && <Rpg />}
      {screen === 'manager' && <App1 />}
      {screen === 'farmer' && <App0 />}
      {!screen && (
        <ul>
          <li>
            <button onClick={showRpg}>RPG</button>
          </li>
          <li>
            <button onClick={showManager}>Resource Manager</button>
          </li>
          <li>
            <button onClick={showFarmer}>Farmer</button>
          </li>
        </ul>
      )}
    </>
  )
}

export function App1() {
  return (
    <div>
      <GameManager>
        <Manager id="time" label="Time" />
        <Manager id="gold" label="Gold" />
        <ul>
          <li>
            <Manager id="ore" label="Ore" buyLabel="Mine" />
            <ul>
              <Manager id="miner" label="Miner" buyLabel="Hire" sellLabel="Fire" />
            </ul>
          </li>
          <li>
            <Manager id="metal" label="Metal" buyLabel="Smelt" />
            <ul>
              <Manager id="smelter" label="Smelter" buyLabel="Hire" sellLabel="Fire" />
            </ul>
          </li>
          <li>
            <Manager id="sword" label="Sword" buyLabel="Craft" />
            <ul>
              <Manager id="weaponsmith" label="Weaponsmith" buyLabel="Hire" sellLabel="Fire" />
              <Manager id="weaponseller" label="Weaponseller" buyLabel="Hire" sellLabel="Fire" />
            </ul>
          </li>
          <li>
            <Manager id="gauntlet" label="Gauntlet" buyLabel="Craft" />
            <ul>
              <Manager id="gauntletsmith" label="Armorsmith (guantlet)" buyLabel="Specialize" sellLabel="Return" />
            </ul>
          </li>
          <li>
            <Manager id="helmet" label="Helmet" buyLabel="Craft" />
            <ul>
              <Manager id="helmetsmith" label="Armorsmith (helmet)" buyLabel="Specialize" sellLabel="Return" />
            </ul>
          </li>
          <li>
            <Manager id="boots" label="Boots" buyLabel="Craft" />
            <ul>
              <Manager id="bootsmith" label="Armorsmith (boots)" buyLabel="Specialize" sellLabel="Return" />
            </ul>
          </li>
          <li>
            <Manager id="armor" label="Armor" buyLabel="Craft" />
            <ul>
              <Manager id="armorsmith" label="Armorsmith" buyLabel="Hire" sellLabel="Fire" />
              <Manager id="armorseller" label="Armor Seller" buyLabel="Hire" sellLabel="Fire" />
            </ul>
          </li>
        </ul>
      </GameManager>
    </div>
  )
}

export function App0() {
  const [gold, setGold] = useState(1e3)
  const [speed, speedCost, buySpeed] = useResource(gold, setGold, 1, 1e6, 1e3)
  const gameTimer = useGameTimer(5 * speed)

  return (
    <div>
      <header>
        <h2>{moment('Jan 2000').add(gameTimer, 'days').format('MMM DD YY')}</h2>
        <div>Gold: {new Intl.NumberFormat().format(gold)}g</div>
        <button onClick={buySpeed} disabled={gold < speedCost}>
          Speed Up ({new Intl.NumberFormat().format(speedCost)}g)
        </button>
      </header>
      <Farm gameTimer={gameTimer} gold={gold} setGold={setGold} />
      {speed > 1 && <Factory gameTimer={gameTimer} gold={gold} setGold={setGold} />}
    </div>
  )
}
